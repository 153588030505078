.dropzone {
  margin: 15px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #acacac;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;

  &:hover {
    transition: .8s;
    color: dimgray;
    border-color: dimgray;
    cursor: pointer;
  }

  &:focus {
    color: dimgray;
    border-color: dimgray;
  }
}

.submitBtn {
  float: right;
}

.deleteBtn {
  float: left;
}