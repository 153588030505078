@font-face {
  font-family: 'OpenSansBold';
  src: url("opensans/OpenSans-Bold.eot");
  src: url("opensans/OpenSans-Bold.eot") format("embedded-opentype"),
  url("opensans/OpenSans-Bold.ttf") format("truetype"),
  url("opensans/OpenSans-Bold.woff") format("woff"),
  url("opensans/OpenSans-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSansLight';
  src: url("opensans/OpenSans-Light.eot");
  src: url("opensans/OpenSans-Light.eot") format("embedded-opentype"),
  url("opensans/OpenSans-Light.ttf") format("truetype"),
  url("opensans/OpenSans-Light.woff") format("woff"),
  url("opensans/OpenSans-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSansRegular';
  src: url("opensans/OpenSans-Regular.eot");
  src: url("opensans/OpenSans-Regular.eot") format("embedded-opentype"),
  url("opensans/OpenSans-Regular.ttf") format("truetype"),
  url("opensans/OpenSans-Regular.woff") format("woff"),
  url("opensans/OpenSans-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSansMedium';
  src: url("opensans/OpenSans-Medium.eot");
  src: url("opensans/OpenSans-Medium.eot") format("embedded-opentype"),
  url("opensans/OpenSans-Medium.ttf") format("truetype"),
  url("opensans/OpenSans-Medium.woff") format("woff"),
  url("opensans/OpenSans-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

//SATA FONTS AREA
@font-face {
  font-family: "sata-fonts";
  src:url("sata/sata-fonts.eot");
  src:url("sata/sata-fonts.eot?#iefix") format("embedded-opentype"),
  url("sata/sata-fonts.woff") format("woff"),
  url("sata/sata-fonts.ttf") format("truetype"),
  url("sata/sata-fonts.svg#sata-fonts") format("svg");
  font-weight: normal;
  font-style: normal;

}

[class^="fonts_iconSata"]:before,
[class*=" fonts_iconSata"]:before {
  font-family: "sata-fonts", serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconSataAward:before {
  content: "\61";
}
.iconSataCar:before {
  content: "\62";
}
.iconSataCheckIn:before {
  content: "\63";
}
.iconSataContactform:before {
  content: "\64";
}
.iconSataEmail:before {
  content: "\65";
}
.iconSataEnPt:before {
  content: "\66";
}
.iconSataHotel:before {
  content: "\68";
}
.iconSataInformation:before {
  content: "\69";
}
.iconSataManageTrip:before {
  content: "\6a";
}
.iconSataParking:before {
  content: "\6b";
}
.iconSataPhone:before {
  content: "\6c";
}
.iconSataSataImagine:before {
  content: "\6d";
}
.iconSataSearch:before {
  content: "\6e";
}
.iconSataStopover:before {
  content: "\6f";
}
.iconSataTimetable:before {
  content: "\70";
}
.iconSataDeparture:before {
  content: "\71";
}
.iconSataCaretsVertical:before {
  content: "\73";
}
.iconSataPet:before {
  content: "\74";
}
.iconSataPreSeat:before {
  content: "\75";
}
.iconSataSataCare:before {
  content: "\76";
}
.iconSataSataImagineMultiple:before {
  content: "\77";
}
.iconSataTax:before {
  content: "\78";
}
.iconSataMeal:before {
  content: "\79";
}
.iconSataBaggage:before {
  content: "\7a";
}
.iconSataCustomized:before {
  content: "\41";
}
.iconSataFlightStatus:before {
  content: "\42";
}
.iconSataAngleDoubleDown:before {
  content: "\43";
}
.iconSataSataPlus:before {
  content: "\44";
}
.iconSataAngleDoubleUp:before {
  content: "\45";
}
.iconSataDestinations:before {
  content: "\46";
}
.iconSataAngleRight:before {
  content: "\4c";
}
.iconSataUser:before {
  content: "\72";
}
.iconSataOtherDestinations:before {
  content: "\47";
}
.iconSataArrowRight:before {
  content: "\48";
}
.iconSataArrowLeft:before {
  content: "\49";
}
.iconSataCalendar:before {
  content: "\4b";
}
.iconSataFlightAzul:before {
  content: "\67";
}
.iconSataAirplane:before {
  content: "\67";
}
.iconSataAdult:before {
  content: "\4a";
}
.iconSataSubmitBtn:before {
  content: "\4d";
}
.iconSataSwitchIcon:before {
  content: "\4e";
}
.iconSataChildren:before {
  content: "\4f";
}
.iconSataInfant:before {
  content: "\50";
}
.iconSataRemoveCourse:before {
  content: "\51";
}
.iconSataAddCourse:before {
  content: "\52";
}
.iconSataDeleteCourse:before {
  content: "\53";
}
.iconSataInfoIcon:before {
  content: "\54";
}
.iconSataCloseIcon:before {
  content: "\55";
}
.iconSataServicos:before {
  content: "\56";
}
.iconSataHiking:before {
  content: "\57";
}
