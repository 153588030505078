.container {
    padding-left: 0 !important;
    padding-right: 0 !important;

    //* {
    //    font-family: OpenSansRegular, OpenSansMedium, OpenSansBold, OpenSansLight, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    //}

    input {
        background: transparent !important;
        margin-bottom: 0;

        &.inputExcluded {
        }

        &:not(.inputExcluded) {
            border: 0 !important;
        }
    }
}