.nextStepBtn {
  margin-top: 10px!important;
  float: right;
}

.tableContainer {
  td {
    padding-right: 2px;
    padding-left: 2px;
  }
}